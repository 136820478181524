(function ($) {

    String.prototype.capitalize = function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }

    var table;

    var reloadTimeout = {left: null, right: null};

    //todo refresh not working due to same url?

    var selectedIds = function (els, selected) {
        if (!els) {
            els = $('#event_selectors .left-side')
        }
        if (selected == null) {
            selected = true
        }
        var kls = selected ? 'a.selected' : 'a:not(.selected)';
        var sel = els.find(kls);
        var ids = sel.map(function () {
            return $(this).attr('data-id')
        });
        var result = $.makeArray(ids);
        return result;
    }

    /*
    var loadVisible = function () {
        var els = $('#event_selectors .left-side');
        $('.toggled-box-content[data-loaded=false]:visible').children().trigger('refresh', [selectedIds(els)]);
    }

    var reloadContent = function (side) {
        var els = $('#event_selectors .' + side + '-side')
        $('.toggled-box-content[data-loaded=true]:visible .' + side + '-side').trigger('refresh', [selectedIds(els)]);
        $('.toggled-box-content[data-loaded=true]:hidden').each(function () {
            $(this).attr('data-loaded', false);
        });
    }
     */

    var updateSelectionLabel = function () {
        var eventName = 'Nothing selected'
        var g1 = table.column(4).$('[data-group="1"].checked');  //column(x).$ - seems to return everything so need to select on data-group
        var g2 = table.columns(5).$('[data-group="2"].checked');
        $('#group-1-count').html("(" + g1.length + ")");
        $('#group-2-count').html("(" + g2.length + ")")
        var group = table.columns([4, 5]).$('.checked');
        if (group.length > 0) {
            eventName = group.first().parents('tr').find('.event-selector-name').text();
            if (group.length > 1) {
                eventName += ' and ' + (group.length - 1) + (group.length == 2 ? ' other' : ' others')
            }
            eventName = '<a href="#" clas="link">' + eventName + '</a>'
        }
        $('#selected-event-label').html(eventName)
        if (eventName == 'Nothing selected') {
            $('.download-item').hide();
        } else {
            $('.download-item').show();
        }
    }

    var refreshTimeout = null;
    var refreshId = 0

    var refresh = function () {
        refreshId += 1;
        clearTimeout(refreshTimeout);
        refreshTimeout = setTimeout(function () {
            updateSelectionLabel()
            groupLabels(function (labels) {
                var params = selected_to_params();
                var nz = $('#nz-results').hasClass('checked')
                $('div[data-url]').trigger('refresh', [params, labels, refreshId]); // add visible here to load currnet page note this breaks tabs
                $('.download-link').each(function () {
                    var that = $(this);
                    var href = that.attr('href').split('?')[0] + '?nz_results=' + nz + params + '&names=' + encodeURIComponent(labels.join(','));
                    var token = that.attr('data-token');
                    if (token) {
                        href += '&token=' + token;
                    }
                    that.attr('href', href);
                })
                var num = $('#event_selectors a.selected').length
                $('#event-count').html(num + ' event' + (num == 0 ? '' : 's'))
            });
        }, 1000);
    }



    var selected_to_params_toggle = function () {
        var result = '';
        for (var i = 1; i < 3; i++) {
            var thisGroup = $('#event_selectors .group' + i);
            var ids = $.makeArray(thisGroup.map(function () {
                return $(this).attr('data-id')
            }));
            result += '&group[]=' + ids.join(',')
        }
        return result
    }

    var selected_to_params_slide = function () {
        var result = '';
        for (var i = 0; i < groups.length; i++) {
            var thisGroup = $('#event_selectors td.' + groups[i] + ' a');
            var ids = $.makeArray(thisGroup.map(function () {
                return $(this).attr('data-id')
            }));
            result += '&group[]=' + ids.join(',')
        }
        return result
    }

    var selected_to_params_check = function () {
        var result = '';
        for (var i = 1; i < 3; i++) {
            var thisGroup = $('#event-checks .checked[data-group=' + i + ']');
            var ids = $.makeArray(thisGroup.map(function () {
                return $(this).attr('data-id')
            }));
            result += '&group[]=' + ids.join(',')
        }
        return result
    }

    var selected_to_params_hidden = function () {
        return "&region_id=" + document.getElementById('event_selection_region_filter').value + "&group[]=" + $('#group1').val() + '&group[]=' + $('#group2').val()
    }

    var selected_to_params = function () {
        return selected_to_params_hidden();
    }

    groupLabels = function (callback) {
        if ($('th[data-edited=true]').length > 0) {
            var labels = $.makeArray($('th[data-group]').map(function () {
                return $(this).html();
            }));
            if (callback) callback(labels);
        } else {
            $.getJSON('/report/group_labels?dmy=0' + selected_to_params(), function (labels) {
                var i = 0
                labels.map(function (label) {
                    i++;
                    $('th[data-group=' + i + ']').html(label)
                })
                if (callback) callback(labels);
            })
        }
    }


    var groups = null

    $(document).on(loadEvent, function () {

            if (notOn('#dashboard')) return false;
            groups = $.makeArray($('th[data-group]').map(function () {
                var c = 'group' + $(this).attr('data-group');
                return c
            }));

            $('th[data-group]').on('input', function () {
                $(this).attr('data-edited', true)
            });

            $('#print-to-pdf').on('click', function(e) {
                var result
                var selected = table.columns([4, 5]).$('.checked');
                debugger
                if (selected.length == 1) {
                    var row = selected.first().parents('tr');
                    if (row.hasClass('validated')) {
                        result = true
                    } else {
                        alert('Sorry, PDF reporting is only available for validated events.  Please contact shane@freshinfo.co.nz if you would like to have your event validated.')
                        result = false
                    }
                } else {
                    alert('Sorry, PDF reporting is not currently available for multiple events.')
                     result = false
                }
                if (!result) {
                    e.stopImmediatePropagation();
                    e.stopPropagation();
                    e.preventDefault();
                }
                return result
            })

            $(document).on('click', 'button[data-url]', function () {
                var onClass = 'btn-toggle-on'
                var offClass = 'btn-toggle-off'
                var that = $(this);
                if (that.hasClass(onClass)) {
                    if (that.parent().find('.'+onClass).length < 2) return;  // this is the only selected element in the filter group so keep it one and drop out
                    that.removeClass(onClass).addClass(offClass)
                } else {
                    that.removeClass(offClass).addClass(onClass)
                }
                var target = $(that.attr('data-target'))
                target.find('.indicates-loading').addClass('loading').html('<i style="display:inline-block" class="ti-reload fa-spin-reverse"></i>');
                groupLabels(function (labels) {
                    var params = selected_to_params();
                    var nz = $('#nz-results').hasClass('checked');
                    console.log('data url button next', nz, params)
                    var url = that.attr('data-url');
                    var href = url + '?nz_results=' + nz + params + '&names=' + encodeURIComponent(labels.join(','));
                    var filters = {}
                    $('#benchmark_toggles .'+onClass).each(function (i) {
                        var $btn = $(this)
                        var filter = $btn.attr('data-filter-name')
                        var val = $btn.attr('data-filter-value')
                        filters[filter] = filters[filter] || []
                        filters[filter].push(val)
                    })
                   var filterParams = ''
                    for (var f in filters) {
                        filterParams += '&' + f + '[]=' + filters[f].join( '&' + f + '[]=')
                    }
                    href += filterParams;
                    $.get(href, function (responseText, textStatus, jqXHR) {
                        var dest = $(that.attr('data-target'));
                       target.html(responseText);
                    })
                });
            })


            $('div[data-url]').on('refresh', function (e, params, names, loadId) {
                var that = $(this);
                var event_ids = selectedIds();
                that.addClass('loading');
                var url = that.attr('data-url');
                if (url) {
                    url += '?nz_results=' + $('#nz-results').hasClass('checked') + params + '&names=' + encodeURIComponent(names.join(','));
                    $.get(url, function (responseText, textStatus, jqXHR) {
                        if ((!loadId) || (loadId == refreshId)) {
                            that.html(responseText);
                            that.parent().attr('data-loaded', true);
                            that.removeClass('loading');
                            // $('[data-toggle="tooltip"]').tooltip();
                            var togParent = $('a[href="#dash-' + that.attr('id') + '"]').parent();
                            if (!responseText) {
                                togParent.hide();
                            } else {
                                togParent.show();
                            }
                        }
                    });
                }
            })
            /*
            $('a.event-selector').on('click', function () {
                var that = $(this);
                var group = $('#navTab1 a.active').attr('data-group');
                // pre and post can't be in same group
                var kls = 'group' + group
                if (that.hasClass(kls)) {
                    that.removeClass(kls);
                } else {
                    for (var i = 0; i < groups.length; i++) {
                        that.removeClass(groups[i]);
                    }
                    that.addClass(kls);
                    $('a.event-selector[data-id=' + that.attr('data-match-id')+ ']').removeClass(kls)
                }
                refresh();
                return false;
            })

            $('a.event-selector').draggable({
                axis: "x",
                revert: 'invalid',
                snap: '.event-destination',
                helper: "clone"
            });
            $('.event-destination').droppable({
                accept: '.event-selector',
                hoverClass: "drag-over",
                drop: function (event, ui) {
                    $(ui.draggable).appendTo($(this));
                    refresh()
                }

            });
            */

            var initialSelectorWidth = null;
            var currentWidth = null;
            var draggingStart = null;
            var startX = null;

            $(document).on('mousemove', function (event) {
                if (draggingStart) {
                    var newX = event.pageX;
                    var diffX = newX - startX;
                    var newWidth = currentWidth + diffX;
                    if (newWidth > initialSelectorWidth) {
                        newWidth = initialSelectorWidth
                    }
                    $('#event-selection').width(newWidth);
                }
            }).on('mouseup', function () {
                currentWidth = $('#event-selection').width() + 1;
                if (currentWidth > initialSelectorWidth) currentWidth = initialSelectorWidth;
                draggingStart = null;
            })

            $('#selection-tab-label').on('mousedown', function (event) {
                var parent = $('#event-selection');
                if (!initialSelectorWidth) initialSelectorWidth = parent.width() + 1;  // handle partial widths (jquery returns whole number)
                if (parent.hasClass('opened')) {
                    currentWidth = parent.width() + 1;
                    if (currentWidth > initialSelectorWidth) currentWidth = initialSelectorWidth;
                    draggingStart = new Date().getTime();
                    startX = event.pageX;
                }
            }).on('mouseup', function () {
                var upTime = new Date().getTime();
                var that = $(this);
                var parent = $('#event-selection');
                if (parent.hasClass('opened')) {
                    if ((upTime - draggingStart) <= 400) {
                        parent.removeClass('opened');
                        var width = parent.width();
                        var tabWidth = that.width()
                        parent.css({'margin-left': (-1 * (width - tabWidth + 15)) + 'px'})
                    }
                } else {
                    parent.addClass('opened');
                    parent.css({'margin-left': 0});
                    parent.width(currentWidth);
                }
            })

            $('a.event-toggle').on('click', function () {
                var that = $(this);
                if (that.hasClass('group1')) {
                    that.removeClass('group1')
                    that.addClass('group2')
                } else if (that.hasClass('group2')) {
                    that.removeClass('group2')
                } else {
                    that.addClass('group1')
                }
                return false
            });

            /*
            $('#validated-event-selector .event-selector-check').on('click', function () {
                var that = $(this);
                if (that.hasClass('checked')) {
                    that.removeClass("checked");
                    $('#event-checks tr.processed').show();
                } else {
                    that.addClass("checked");
                    $('#event-checks tr.processed').hide();
                    $('#event-checks tr.processed .event-selector-check').removeClass('checked');
                    refresh();
                }
            });
             */

            $('#nz-results').on('change', function () {
                var checked = this.checked;
                var that = $(this);
                if (!checked) {
                    that.removeClass("checked");
                    $('#navTab1 .nav-item').hide();
                    $('#navTab1 .host-region').show();
                } else {
                    //check selected item to see if NZ otherwise select overview
                    var sel = $('#navTab1 .nav-link.active').parent();
                    if (!sel.hasClass('new-zealand')) {
                        $('#dash-overview-tab').click();
                    }
                    that.addClass("checked");
                    $('#navTab1 .nav-item').hide();
                    $('#navTab1 .new-zealand').show();
                }
                refresh();
            });

            var removeEventId = function (el, id) {
                var parts = el.val() ? el.val().split(',') : [];
                var idx = parts.indexOf(id);
                if (idx >= 0) {
                    parts.splice(idx, 1);
                    el.val(parts.join(','))
                }
            }

            var addEventId = function (el, id) {
                var parts = el.val() ? el.val().split(',') : [];
                parts.push(id);
                el.val(parts.join(','));
            }

            $('#event-checks tbody .event-selector-check').on('click', function () {
                var that = $(this);
                var id = that.attr('data-id');
                var other = that.attr('data-other');
                var group = that.attr('data-group');
                // remove id from both group fields
                removeEventId($('#group1'), id);
                removeEventId($('#group2'), id);
                if (that.hasClass('checked')) {
                    that.removeClass("checked");
                    that.html('');
                } else {
                    $('#ev-group1-' + id).removeClass('checked').html('');
                    $('#ev-group2-' + id).removeClass('checked').html('');
                    if (other) {
                        $('#ev-group' + group + '-' + other).removeClass('checked').html('')
                    }
                    addEventId($('#group' + group), id);
                    that.addClass("checked");
                    that.html('<span>checked</span>')
                }
                //
                refresh();
                checkSelectAll();
            })

            $('#event-select-ok').on('click', function () {
                refresh();
            });

            $('#selected-event-label').on('click', 'a', function () {
                if (!$('#selected-events-only').hasClass('checked')) {
                    $('#selected-events-only').click();
                }
                return false
            })

            /*
            $('#print-to-pdf, #export-to-excel').on('click', function () {
                var that = $(this);
                var href = that.attr('href');
                href = href.replace('&nz_results=true', '');
                href = href.replace('&nz_results=false', '');
                href += '&nz_results=' + $('#nz-results').hasClass('checked')
                that.attr('href', href)
            })
            */

            $('#dashboard').on('click', '.modify-param', function (e) {
                var that = $(this);
                var param = that.attr('data-param');
                var value = that.attr('data-param-value')
                var panel = that.parents('[data-url]');
                groupLabels(function (labels) {
                    var params = selected_to_params()
                    params += '&' + param + '=' + encodeURIComponent(value);
                    panel.trigger('refresh', [params, labels]);
                });
                e.stopPropagation();
                e.preventDefault();
                return false;
            }).on('click', '.visitation-selector', function () {
                var els = $('.visitation-selector:checked')
                if (els.length >= 1) {
                    $('#visitation .domestic, #visitation .international, #visitation .total').hide();
                    if (els.length > 1) {
                        $('#visitation .total').show();
                        $('#visitation-label').html('All visitors')
                    } else {
                        $('#visitation .' + els[0].value).show();
                        $('#visitation-label').html(els[0].value.capitalize() + ' visitors')
                    }
                } else {
                    return false;
                }
            }).on('click', '.help-info', function () {
                var str = $(this).attr('data-original-title');
                alert(str);
                return false;
            });

            $('#portfolio-chart-selection').on('click', 'button', function () {
                var that = $(this)
                $('#portfolio-chart-selection button').removeClass('btn-primary').addClass('btn-default');
                that.addClass('btn-primary');
                $('#dash-portfolio .portfolio').hide();
                $('#' + that.attr('data-toggle')).show();
            });

            $('#dashboard-area-selection').on('click', 'button', function () {
                var that = $(this)
                $('#dashboard-area-selection button').removeClass('btn-primary').addClass('btn-default');
                that.addClass('btn-primary');
                $('#nz-results')[0].checked = that.attr('id') == 'new-zealand-btn' ? true : false;
                $('#nz-results').trigger('change')
            });

            jQuery.extend(jQuery.fn.dataTableExt.oSort, {
                "date-uk-pre": function (a) {
                    if (a == null || a == "") {
                        return 0;
                    }
                    var ukDatea = a.split('-');
                    return (ukDatea[2] + ukDatea[1] + ukDatea[0]) * 1;
                },

                "date-uk-asc": function (a, b) {
                    return ((a < b) ? -1 : ((a > b) ? 1 : 0));
                },

                "date-uk-desc": function (a, b) {
                    return ((a < b) ? 1 : ((a > b) ? -1 : 0));
                }
            });

            var eventChecks = $('#event-checks');
            if (eventChecks.length > 0) {
                var order = [[0, 'desc']]
                var params = document.location.search;
                var parts = params.split('&order=');
                if (parts.length > 1) {
                    var rest = parts[1];
                    parts = rest.split('&');
                    rest = parts[0];
                    parts = rest.split(',');
                    order = [[parseInt(parts[0]), parts[1]]];
                }

                var columns = [
                    {"orderable": true, visible: false, searchable: false},
                    {"orderable": true},
                    {"orderable": true, "type": "date-uk"},
                    {"orderable": true},
                    {"orderable": false},
                    {"orderable": false},
                    {"orderable": false, visible: false},
                    {"orderable": false, visible: false},
                    {"orderable": false, visible: false},
                    {"orderable": false, visible: false}
                ];
                /*
                if (eventChecks.find('th').length > columns.length) {
                    columns = columns.concat([{"orderable": false}]);
                }
*/

                eventChecks.on('init.dt', function () {
                    setTimeout(function () {
                        regionFilter()
                    }, 1)
                });

                table = eventChecks.DataTable({
                    "dom": 'lrtip',
                    'responsive': true,
                    "searching": true,
                    "paging": true,
                    "language": {
                        "paginate": {
                            "previous": "Prev"
                        }
                    },
                    "lengthChange": false,
                    "pageLength": 25,
                    "info": false,
                    "order": order,
                    "columns": columns,
                    "initComplete": function (settings, json) {

                    }
                });

                var checkSelectAll = function () {
                    var page = table.rows({page: 'current'}).count();
                    var checked = $('#event-checks tbody .event-selector-check.checked').length;
                    if (checked >= page) {
                        $('#select-all').addClass('checked')
                    } else {
                        $('#select-all').removeClass('checked')
                    }
                }

                eventChecks.on('order.dt', function () {
                    var order = table.order();
                    var pdfExport = $('#print-to-pdf');
                    var url = pdfExport.attr('href');
                    var parts = url.split('?')
                    var start = parts[0] //main part of url has single query arg - token so get that
                    url = start + '?order=' + order[0][0] + ',' + order[0][1];
                    if (parts.length > 1) {
                        parts.shift();
                        url += '&' + parts.join('&');
                    }
                    pdfExport.attr('href', url);
                }).on('draw.dt', function () {
                    checkSelectAll();
                });


                eventChecks.parent().addClass('table-responsive'); // need to do this here as datatable adds wrappers

                function strToNZDate(str) {
                    return str.split('-').reverse().join('-')
                }

                $.fn.dataTable.ext.search.push(
                    function (settings, data, dataIndex) {
                        var from = strToNZDate($('#event-selection-datefrom-filter').val());
                        var upto = strToNZDate($('#event-selection-dateto-filter').val());
                        var eventDate = strToNZDate(data[2]);
                        if ((!from) && (!upto)) return true;
                        if (!eventDate) return false;
                        return (((eventDate >= from) || (!from)) && ((eventDate <= upto) || (!upto)))
                    }
                );

                $.fn.dataTable.ext.search.push(
                    function (settings, data, dataIndex) {
                        var selected = $('#selected-events-only').hasClass('checked');
                        if (selected) {
                            return data[4] == 'checked' || data[5] == 'checked';
                        } else {
                            return true;
                        }
                    }
                );

                var setClearFilterStatus = function () {
                    var allClear = true;
                    $('.dashboard-filter').each(function () {
                        if ($(this).val() != '') allClear = false;
                    });
                    if (allClear) {
                        $('#event-selection-clear-filter').addClass('disabled');
                    } else {
                        $('#event-selection-clear-filter').removeClass('disabled');
                    }
                }

                var regionFilter = function () {
                    var val = document.getElementById('event_selection_region_filter').value;
                    if (val != '') {
                        var g1 = table.column(4).nodes().to$().find("[data-region]:not([data-region='" + val + "']).checked");
                        var g2 = table.column(5).nodes().to$().find("[data-region]:not([data-region='" + val + "']).checked");
                        g1.trigger('click')
                        g2.trigger('click')
                    }
                    table.columns(8).search(val).draw();
                }

                $('#event_selection_region_filter').on('change', function () {
                    // to do uncheck selected
                    regionFilter();
                });
                $('#event-selection-name-filter').on('keyup', function () {
                    setClearFilterStatus();
                    table.search(this.value).draw();
                });
                $('#event-selection-datefrom-filter, #event-selection-dateto-filter').on('keyup change', function () {
                    setClearFilterStatus();
                    table.draw();
                });
                $('#event-selection-prepost-filter').on('change', function () {
                    var val = $(this.val)
                    table.columns(3).search(this.value).draw();
                });
                $('#selected-events-only').on('click', function () {
                    var that = $(this);
                    that.toggleClass('checked');
                    table.rows().invalidate().draw();
                });
                $('#validated-events-only').on('click', function () {
                    var that = $(this);
                    that.toggleClass('checked');
                    var query = that.hasClass('checked') ? 'validated' : ''
                    table.columns(6).search(query).draw()
                });
                $('#benchmark-events-only').on('click', function () {
                    var that = $(this);
                    that.toggleClass('checked');
                    var query = that.hasClass('checked') ? 'benchmark' : ''
                    table.columns(9).search(query).draw()
                });

                var selectNone = function () {
                    $(table.rows().nodes()).find('.event-selector-check').removeClass('checked').html('');
                    $('#group1').val('');
                    $('#group2').val('');
                }

                var selectEvent = function (row) {
                    $(row.cells[3]).find('div').trigger('click');
                }
                /*
                                $('#dashboard-selects-all .select-all').click(function (e) {
                                    selectNone();
                                    var that = $(this);
                                    var id = that.attr('id');
                                    if (id != 'select-none') {
                                        var filter = {};
                                        if (id == 'select-page') {
                                            filter = {page: 'current'}
                                        } else if (id == 'select-found') {
                                            filter = {search: 'applied'}
                                        }
                                        var rows = table.rows(filter).nodes();
                                        for (var i = 0; i < rows.length; i++) {
                                            selectEvent(rows[i])
                                        }
                                        for (var i = 0; i < rows.length; i++) {
                                            var row = rows[i];
                                            if (!$(row.cells[3]).find('div').hasClass('checked')) {
                                                $(row.cells[4]).find('div').trigger('click');
                                            }
                                        }
                                    } else {
                                        refresh();
                                    }
                                    return false;
                                });
                */
                $('#select-all').click(function (e) {
                    var that = $(this);
                    that.toggleClass('checked');
                    var addCheck = that.hasClass('checked');
                    var filter = {page: 'current'};
                    var rows = table.rows(filter).nodes();
                    /*
                    for (var i = 0; i < rows.length; i++) {
                        selectEvent(rows[i])
                    }
                     */
                    for (var i = 0; i < rows.length; i++) {
                        var row = rows[i];
                        if (!$(row.cells[3]).find('div').hasClass('checked')) {
                            if (addCheck) $(row.cells[3]).find('div').trigger('click');
                        } else {
                            if (!addCheck) $(row.cells[3]).find('div').trigger('click');
                        }
                        if (!addCheck) {
                            if ($(row.cells[4]).find('div').hasClass('checked')) $(row.cells[4]).find('div').trigger('click');
                        }
                    }
                    return false;
                });

                $('#event-selection-clear-filter').on('click', function () {
                    $('#event-selection-name-filter, #event-selection-datefrom-filter, #event-selection-dateto-filter').val('');
                    setClearFilterStatus();
                    /*
                    if ($('#validated-events-only').hasClass('checked')) {
                        $('#validated-events-only').click();
                    }
                    if ($('#selected-events-only').hasClass('checked')) {
                        $('#selected-events-only').click();
                    }
                     */
                    table.search('').draw();
                });
            }

            refresh();
        }
    )
})(jQuery);