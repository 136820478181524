// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

window.$ = jQuery;
window.jQuery = jQuery;
window.jquery = jQuery;

// import 'select2'
// import 'select2/dist/css/select2.css'

import 'selectize'
import 'selectize/dist/css/selectize.css'

require("chart.js")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import "stylesheets/application"

import "application/globals"
import "application/jquery-extensions"
import "application/cookie"
import "application/multi-record-select"
import "application/pusher"
import "application/jquery.dataTables"
import "application/chosen-jquery"

import "application/modal" // from bootstrap
import "application/util"
import "application/tab" // from bootstrap
import "application/collapse" // from bootstrap
// import "application/tooltip" // from bootstrap
// import "application/dropdown" // from bootstrap

//import "application/bootstrap-select"
// import "application/bootstrap-toggle"

import "application/column_sort"
import "application/datepicker"
import "application/du_controller"
import "application/jQuery.tagify.min"
import "application/event"
import "application/events"
import "application/report"
import "application/sticky"
import "application/regions"

import "application/application"